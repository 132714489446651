import React, { ReactNode } from 'react';
// import { graphql, useStaticQuery, HeadFC } from 'gatsby';
import { HeadFC } from 'gatsby';

import Nav from './Nav';
import Content from './Content';
// import GithubCorner from './GithubCorner';
import './globalStyles.css';

interface NavProps {
  hideNav: boolean;
  children: ReactNode;
}

const Layout = ({ children, hideNav}: NavProps) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         gitHubUrl
  //       }
  //     }
  //   }
  // `)
  return (
  <>
    {/* <GithubCorner url={data.site.siteMetadata.gitHubUrl} /> */}
    <Nav />
    <Content>{children}</Content>
  </>
  );
};

export default Layout;

export const Head: HeadFC = () => <>
  <link
    rel="preload"
    href="/fonts/NeueHaasDisplay-Medium.woff2"
    as="font"
    crossOrigin="anonymous"
    type="font/woff2"
  />
  <link
    rel="preload"
    href="/fonts/NeueHaasDisplay-Roman.woff2"
    as="font"
    crossOrigin="anonymous"
    type="font/woff2"
  />
</>
