import React from 'react';
import { config } from '@react-spring/web'

import useScrollTo from '../hooks/useScrollTo';

import './Footnote.css';


interface Props {
  children: string;
  className?: string;
}

const Footnote: React.FC<Props> = ({ children, className = 'footnote'}) => {
  const { scrollTo } = useScrollTo(config.slow)
  return (
    <button className="Footnote-Button" onClick={() => scrollTo(document.querySelector(`#${className}-${children}`))
    }>
      <sup>{children}</sup>
    </button>
  );
};

export default Footnote;
