import React, { ReactNode } from 'react';
import IFrame from '../components/mdx/IFrame';
import Flex from '../components/mdx/Flex'
import Wirewax from '../components/mdx/Wirewax'
import Video from '../components/mdx/Video'
import Footnote from '../components/mdx/Footnote';
import Code from '../components/mdx/Code'
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"

import { preToCodeBlock } from "../utils"

interface ContentProps {
  children?: ReactNode;
  className?: string;
}

const Content: React.FC<ContentProps> = ({ children, className = '' }) => {
  const components = {
    Link,
    IFrame,
    Flex,
    Wirewax,
    Video,
    Footnote,
    // raw Code component for imported text
    Code,
    // pre for smaller inline code snippets
    pre: (preProps: any) => {
      const props = preToCodeBlock(preProps);
      console.log("props", props)
      console.log("preProps", preProps)

      if (props) { return <Code {...props} /> }
      return <pre {...preProps} />
    },
  } as any;
  return (
    <div className={`Content ${className}`}>
      <MDXProvider components={components}>{children}</MDXProvider>
    </div>
  );
};


export default Content;
