import React from "react";
import { Highlight, Language, themes } from "prism-react-renderer";
import './Code.css';

import { copyToClipboard } from '../../utils'

interface CodeProps {
  codeString: string;
  language: Language;
  highlight?: string;
  filename?: string;
  copy?: boolean;
}

const calculateLinesToHighlight = (meta?: string) => {
  if (!meta) {
    return () => false
  }
  const lineNumbers = meta
    .split(`,`)
    .map((v) => v.split(`-`).map((x) => parseInt(x, 10)))

  return (index: number) => {
    const lineNumber = index + 1
    const inRange = lineNumbers.some(([start, end]) =>
      end ? lineNumber >= start && lineNumber <= end : lineNumber === start
    )
    return inRange
  }
}

const Code: React.FC<CodeProps> = ({ codeString, language, highlight, filename, copy }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const shouldHighlightLine = calculateLinesToHighlight(highlight)

  // https://www.jimraptis.com/blog/gatsby-mdx-copy-code-button-with-confetti react-live
  return (
    <Highlight
      code={codeString}
      language={language}
      theme={themes.nightOwl}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className="highlightContainer" data-language={language}>
          <div className="highlightHeader">
            {filename && <span className="highlightFilename">{filename}</span>}
            <span className="highlightLang">{language}</span>
            {copy && tokens.length > 1 &&
              <button
                className="copyButton"
                onClick={() => {
                  copyToClipboard(codeString)
                  setIsCopied(true)
                  setTimeout(() => setIsCopied(false), 3000)
                }}
              >
                {isCopied ? "🎉 Copied!" : "Copy"}
              </button>
            }
          </div>
          <pre className={`line ${className}`} style={style}>
          {tokens.length > 1 ?
            tokens.map((line, i) => {
              const lineProps = getLineProps({ line, key: i })

              if (shouldHighlightLine(i)) {
                lineProps.className = `${lineProps.className} highlightLine`
              }
              return (
                <div key={i} {...lineProps}>
                  <span className='lineNumber'>{i + 1}</span>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </div>
              )
          })
          :
          tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
          </pre>
        </div>
      )}
    </Highlight>
  )
};

export default Code;
