import React, { useState } from 'react';
import './Video.css';

export interface VideoProps {
  sources: string[];
  controls?: boolean;
  width?: number;
  height?: number;
  title?: string;
  poster?: string;
  mobileBlock?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  loop?: boolean;
}

const mimeTypes: Record<string, string> = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
};

const Video: React.FC<VideoProps> = ({ sources, title, poster, mobileBlock, controls, autoPlay = true, muted = true, playsInline = true, loop = true }) => {
return (
  <>
  {mobileBlock ? <blockquote className={`video-mobile-block`}>This content is best viewed on desktop.</blockquote> : null }
  <div className={`video-container ${mobileBlock ? 'mobile-block': ''}`}>
    {title && <h3 className={`video-title`}>{title}</h3>}
    <video controls={controls} poster={poster} autoPlay={autoPlay} muted={muted} playsInline={playsInline} loop={loop}>
      {sources.map((source, index) => {
        const extension = source.split('.').pop()?.toLowerCase();
        const type = mimeTypes[extension || 'mp4'] || 'video/mp4';
        return <source key={index} src={source} type={type} />;
      })}
    </video>
  </div>
  </>
)};

export default Video;
