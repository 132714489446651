import React, { useState, ReactNode, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Menu, X } from 'react-feather';
import { useSpring, animated, useScroll, useReducedMotion } from '@react-spring/web';

import './Nav.css';

const Navigation = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const reducedMotion = useReducedMotion()
  const isMainPage = location.pathname === '/';

  // Only close nav if it is open
  const handleLinkClick = (): void => setActive(false);

  const checkLinkMatch = (to: string, pathname: string): boolean => {
    const secondSlash = pathname.indexOf('/', pathname.indexOf('/') + 1);
    const shortPath = pathname.slice(0, secondSlash);
    const path = secondSlash === -1 ? pathname : shortPath;
    return to === path;
  };

  const NavLink: React.FC<{ to: string; children: ReactNode }> = ({
    to,
    children,
    ...props
  }) => (
    <Link
      to={to}
      state={{ transition: 'nav' }}
      className={`NavLink ${checkLinkMatch(to, location.pathname) ? 'active' : ''}`}
      onClick={handleLinkClick}
      {...props}
    >
      {children}
    </Link>
  );

  const [navSpring, navApi] = useSpring(() => ( {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: { duration: 600 },
    delay: 200
  }));

  const [mainNavSpring, mainNavApi] = useSpring(() => ( {
    opacity: 0,
    config: { duration: 800 },
  }));

  const { } = useScroll({
    onChange: ({ value: { scrollYProgress } }) => {
      if (isMainPage){
        if (scrollYProgress > 0.06) {
          setHasScrolled(true);
          mainNavApi.start({ opacity: 1, config: { duration: 600 },
            delay: 200 })
        } else {
          setHasScrolled(false);
          mainNavApi.start({ opacity: 0 })
        }
      }else {
        navApi.start({ opacity: 1, config: { duration: 600 },
          delay: 200 })
      }
      // navApi.start({ opacity: 1 })
    },
    default: {
      immediate: true,
    },
  })
  const activeSpring = isMainPage ? mainNavSpring : navSpring;

  useEffect(() => {
    // Timer to increment idle time
    let timer: any;
    if(!hasScrolled) {
      timer = setInterval(() => {
        mainNavApi.start({
          opacity: 1,
          config: { duration: 600 },
          delay: 200
        })
      }, 2000);
    }
    // Clear timer on component unmount
    return () => timer && clearInterval(timer);
  }, [hasScrolled]);

  return (
    <animated.nav style={reducedMotion ?{ opacity: 1} : activeSpring} className={`Nav ${active ? 'Nav-active' : ''} fixed`}>
      <div className="Nav--Container container">
        <Link className="Nav--Logo" to="/" onClick={() => {
            mainNavApi.start({ opacity: 0, config: { duration: 0 },
              delay: 0 })
            handleLinkClick()
          }}>
          Graydon Pleasants
        </Link>
        <div className="Nav--Links">
          <NavLink  to="/projects">Projects</NavLink>
          <NavLink to="/posts">Writing</NavLink>
          <NavLink to="/about">About</NavLink>
          {process.env.NODE_ENV === 'development' && <NavLink to="/drafts">Drafts</NavLink>}
        </div>
        <button aria-label="Menu" className="Nav--MenuButton" onClick={() => setActive(!active)}>
          {active ? <X /> : <Menu />}
        </button>
      </div>
    </animated.nav>
  );
};

export default Navigation;
