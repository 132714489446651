import React, { useState } from 'react';
import './IFrame.css';

export interface IFrameProps {
  src: string;
  width?: number;
  height?: number;
  title?: string;
  lazyImage?: string;
  mobileBlock?: boolean;
}

const IFrame: React.FC<IFrameProps> = ({ src, width = 1920, height = 1080, title, lazyImage, mobileBlock }) => {
const [show, changeShow] = useState(lazyImage === undefined);
if (lazyImage){
  console.log('src',lazyImage);
  console.log('typeof src',typeof lazyImage);
}

return (
  <>
  {mobileBlock ? <blockquote className={`iframe-mobile-block`}>This content is best viewed on desktop.</blockquote> : null }
  <div className={`iframe-outer-container ${mobileBlock ? 'mobile-block': ''}`}>
    {title && <h3 className={`iframe-title`}>{title}</h3>}
    <div className={`iframe-container`} style={{ paddingBottom: `${(height / width) * 100}%` }}>
      {show ? <iframe
        width={width}
        height={height}
        src={src}
        className={`iframe`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      /> : <img className={`iframe-placeholder`} onClick={()=> changeShow(!show)} src={lazyImage || ''} alt={title || 'click to show iframe'}/> }
    </div>
  </div>
  </>
)};

export default IFrame;
