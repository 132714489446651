import React, { ReactNode } from 'react';
import './Flex.css';

export interface FlexProps {
  children: ReactNode;
}

const Flex: React.FC<FlexProps> = ({ children, ...props }) => (
  <div {...props} className={`flex`}>
    {children}
  </div>
);

export default Flex;
