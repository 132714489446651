// import { Language } from "prism-react-renderer";

interface PreProps {
  children: React.ReactElement<{
    className: string;
    children: string;
  }>;
}

export const preToCodeBlock = (preProps: PreProps) => {
  if (preProps?.children?.type === `code`) {
    const {
      children: codeString,
      className = ``,
      ...props
    } = preProps.children.props

    const match = className.match(/language-([\0-\uFFFF]*)/)
    return {
      codeString: codeString.trim(),
      className,
      language: match !== null ? match[1] : ``,
      ...props,
    }
  }
}

export const copyToClipboard = async (str: string) => {
  if( navigator.clipboard){
    try {
      await navigator.clipboard.writeText(str);
      console.log('Page URL copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  } else {
    // Fallback for old browsers
    const el = document.createElement("textarea")
    el.value = str
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }

}
