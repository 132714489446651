import React from 'react';

const Wirewax: React.FC= () => {
  return (
    <blockquote style={{marginBottom: 36}}>
       <strong>Heads up:</strong> these videos were built during my time at <a target='_blank' href="https://www.wirewax.com/studio/">Wirewax</a> and were made possible in part due to their motion tracking technology powering these experiences. Now that Wirewax <a href="https://www.wirewax.com/blog/wirewax-is-now-part-of-vimeo/">has been acquired</a> and it's features have been integrated into Vimeo, similar results should be achievable with their <a target='_blank' href="https://vimeo.com/features/interactive-video?utm_source=wirewax">interactive toolset.</a>
    </blockquote>
  );
};

export default Wirewax;
